<template>
    <div class="darmin card">
        <div
            role="button"
            class="card-header pb-0 d-flex align-items-center justify-content-between"
            data-bs-toggle="collapse"
            :data-bs-target="`#card-${ramId}`"
            aria-expanded="true"
            :aria-controls="`card-${ramId}`"
        >
            <h5>Archivos</h5>
            <i class="fas fa-angle-up"></i>
        </div>
        <div class="card-body theme-form">
            <div class="collapse show" :id="`card-${ramId}`">
                <div class="row mb-2" v-if="!readOnly">
                    <div class="col">
                        <div class="dragNdrop" v-bind="getRootProps()">
                            <input v-bind="getInputProps()" />
                            <div v-if="isDragActive" class="text-center">
                                <p>Suelta tus archivos aqui ...</p>
                            </div>
                            <div v-else class="text-center">
                                <i class="far fa-cloud-upload"></i>
                                <p>Arrastra tus archivos aqui! o click para seleccionar</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="info.length">
                    <div class="files">
                        <VueDraggableNext class="dragArea row w-full" :list="info" @change="log">
                            <div
                                class="col-md-6 col-lg-4 col-xl-3 mb-1 p-2"
                                v-for="file in info"
                                :key="file.id"
                            >
                                <ResultadoFile
                                    v-model="file.selected"
                                    :file="file"
                                    @onChange="log"
                                    @onRemove="(data) => $emit('onRemove', data)"
                                    :readOnly="readOnly"
                                    :selectable="selectable"
                                />
                            </div>
                        </VueDraggableNext>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { useDropzone } from 'vue3-dropzone';
import { VueDraggableNext } from 'vue-draggable-next';
import ResultadoFile from './ResultadoFile.vue';

import useUploadFile from '../services/useUploadFile';

export default defineComponent({
    name: 'ResultadoFiles',
    components: { VueDraggableNext, ResultadoFile },
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
        idResultado: String,
        readOnly: Boolean,
        selectable: { type: Boolean, default: true },
    },
    setup(props, ctx) {
        const enabled = ref(true);
        const dragging = ref(false);
        const ramId = ref(Math.floor(Math.random() * 1000));

        const relativeUrl = `/care/examen/resultado/files/${props.idResultado}`;
        const { saveFile } = useUploadFile(relativeUrl);

        const uploader = ref();
        const info = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                ctx.emit('update:modelValue', value);
                ctx.emit('onUpdate');
            },
        });

        const handleRemove = (id) => {
            console.log('remove', id);
            info.value = info.value.filter((i) => i.file.id !== id);
        };

        const concatFiles = (files) => {
            info.value = info.value.concat(files);
        };

        async function onDrop(acceptFiles, rejectReasons) {
            const files = [];
            // eslint-disable-next-line array-callback-return
            // eslint-disable-next-line no-restricted-syntax
            for (const file of acceptFiles) {
                const f = { order: 999 };
                // eslint-disable-next-line no-await-in-loop
                f.file = await saveFile(file);
                files.push(f);
            }
            concatFiles(files);
            // console.log(acceptFiles);
            if (rejectReasons.length) console.log(rejectReasons);
        }

        const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

        function log() {
            ctx.emit('onUpdate');
        }

        const getUrl = (id) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            `${process.env.VUE_APP_MAIN_SERVICE}/care/examen/resultado/files/view/${id}`;
        return {
            ramId,
            info,
            saveFile,
            uploader,
            getRootProps,
            getInputProps,
            ...rest,
            enabled,
            dragging,
            log,
            getUrl,
            handleRemove,
        };
    },
});
</script>

<style scoped>
.dragNdrop {
    margin-right: auto;
    margin-left: auto;
    padding: 50px;
    border: 2px dashed var(--theme-default-font);
    border-radius: 15px;
    -o-border-image: none;
    border-image: none;
    background: rgba(99, 98, 231, 0.1);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 150px;
    position: relative;
    cursor: pointer;
}
.dragNdrop:hover {
    background-color: rgb(97 97 232 / 16%);
}

.dragNdrop i {
    font-size: 3em;
}
.dragNdrop p {
    font-weight: 600;
    font-size: 16px;
}
.files .file {
    cursor: pointer;
    border: 1px solid #efefef;
    border-radius: 5px;
    background-color: #fdfdfd;
}
.files .file.selected {
    box-shadow: 1px 1px 5px #1e2f6580;
}
.files .file:hover {
    background-color: #efefef;
    border-color: #fdfdfd;
}
.files .title {
    margin: 0;
}
.files i {
    cursor: pointer;
}
</style>
